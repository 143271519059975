import React from "react"
import { Link } from "gatsby"

const Top = () => {
    return (
        <div className="top d">
            <div className="top-overlay d-flex align-items-end justify-content-center text-center">
                <div className="text-center text-white">
                    <h1>Marcelo B. Fernan</h1>
                    <h4 className="subtitle">Former Chief Justice of the Supreme Court and Senate President of the Philippines</h4>
                    <button className="scroll-link"><Link to="/#timeline"><u>Learn more about him</u></Link></button>
                </div>
            </div>
        </div>
    )
}

export default Top