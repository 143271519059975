import React from "react"

import ParentComponent from './menu.js'

const Header = () => {
  return (
  <ParentComponent />
)}

export default Header
