import React from "react"
import { slide as Menu } from "react-burger-menu"

class ParentComponent extends React.Component {
    constructor (props) {
      super(props)
      this.state = {
        menuOpen: false
      }
    }

    handleStateChange (state) {
      this.setState({menuOpen: state.isOpen})  
    }

    closeMenu () {
      this.setState({menuOpen: false})
    }
  
    toggleMenu () {
      this.setState(state => ({menuOpen: !state.menuOpen}))
    }
  
    render () {
        var menuStyles = {
            bmBurgerButton: {
              position: 'fixed',
              width: '36px',
              height: '30px',
              left: '36px',
              top: '36px'
            },
            bmBurgerBars: {
              background: '#373a47'
            },
            bmBurgerBarsHover: {
              background: '#a90000'
            },
            bmCrossButton: {
              height: '24px',
              width: '24px'
            },
            bmCross: {
              background: '#bdc3c7'
            },
            bmMenuWrap: {
              position: 'fixed',
              height: '100%',
              top: '0'
            },
            bmMenu: {
              background: '#202020',
              padding: '2.5em 1.5em 0',
              fontSize: '1.15em'
            },
            bmMorphShape: {
              fill: '#373a47'
            },
            bmItemList: {
              color: '#b8b7ad',
              padding: '0'
            },
            bmItem: {
              display: 'inline-block'
            },
            bmOverlay: {
              background: 'rgba(0, 0, 0, 0.3)',
              top: '0'
            }
          }
      return (
        <div className="menu">
          <Menu 
            isOpen={this.state.menuOpen}
            onStateChange={(state) => this.handleStateChange(state)}
            styles={menuStyles} 
            width={ '85%' }
          >
            <p>This website was made to share the legacy of the virtues, character, life and works of Marcelo B. Fernan. It was created through interviews of Alejandrina Fernan Del Rosario conducted by Marcelo Fernan III and Margarita Fernan Granthom, research and copy by Jose Marcelo Borromeo, and photos by Maria Emiliana Fernan Borromeo.</p>
            <ul>
                <li>Menu</li>
                <li>
                    <a onClick={() => this.closeMenu()} href="/#timeline" className="menu-item">Timeline</a>
                </li>
                <li>
                    <a onClick={() => this.closeMenu()} href="http://www.geocities.ws/diday_fr/ftree.htm" className="menu-item">Fernan Family Tree</a>
                </li>
            </ul>
          </Menu>
        </div>
      )
    }
  }

  export default ParentComponent